<template>
    <div class="apaympro-container">

        <!-- Loader -->
        <loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" loader="bars"
            :opacity="0.7"></loading>
        <!-- Loader -->

        <div class="apaympro-wrapper animated fadeIn container" style="width: 550px">
            <div class="apaympro-page">

                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->
                <div class="apaympro-header my-3" style="margin-top: 15px">
                    <div class="company-logo"
                        style="display: flex;justify-content: center; align-items: center; width: auto;">
                        <img src="@/assets/apaym-pay.png" width="200" alt="Logo APaym Pro" />
                    </div>
                    <div class="page-info">
                        <h3 class="page-name font-20 font-weight-bold">
                            TRANSFERT D'ARGENT
                        </h3>

                        <p class="company-name text-dark font-14" style="text-transform: none;">
                            Disposez-vous d'une carte APAYM Ecobank (Physique/Virtuelle) ?
                            Transférez votre argent vers le mobile money de votre choix ou
                            vers une autre carte APAYM UBA (Physique/Virtuelle)
                        </p>
                    </div>

                </div>
                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->

                <!-- **** -->
                <!-- **** -->
                <!-- Body -->
                <!-- **** -->
                <!-- **** -->
                <div class="body">
                    <form name="payment-form" class="payment-form">

                        <!-- ***************************************** -->
                        <!-- ***************************************** -->
                        <!-- ID de la carte et les 4 derniers chiffres -->
                        <!-- ***************************************** -->
                        <!-- ***************************************** -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>ID de la carte</span>
                                    </div>
                                    <input class="element-is-input" type="tel" v-mask="'########'" name="card_id"
                                        v-model="infos_card_ecobank.card_id"
                                        :disabled="show_solde && show_mode_transfert" />
                                </div>

                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span>4 derniers chiffres</span>
                                    </div>
                                    <input class="element-is-input" type="tel" v-mask="'####'" name="last_4_digits"
                                        v-model="infos_card_ecobank.last_4_digits"
                                        :disabled="show_solde && show_mode_transfert" />
                                </div>
                            </div>
                        </div>
                        <!-- ***************************************** -->
                        <!-- ***************************************** -->
                        <!-- ID de la carte et les 4 derniers chiffres -->
                        <!-- ***************************************** -->
                        <!-- ***************************************** -->

                        <!-- ********** -->
                        <!-- ********** -->
                        <!-- Le captcha -->
                        <!-- ********** -->
                        <!-- ********** -->
                        <div class="text-center my-5" v-if="!show_solde && !show_mode_transfert && show_btn_confirm">
                            <vue-hcaptcha class="h-captcha" @verify="onVerify" @expired="onExpired" @error="onError"
                                sitekey="cc4778d8-c49d-40ad-950c-ab566138bda2"></vue-hcaptcha>
                        </div>
                        <!-- ********** -->
                        <!-- ********** -->
                        <!-- Le captcha -->
                        <!-- ********** -->
                        <!-- ********** -->


                        <!-- ****************** -->
                        <!-- ****************** -->
                        <!-- Button submit form -->
                        <!-- ****************** -->
                        <!-- ****************** -->
                        <div class="element-wrap element-wrap-for-submit" v-if="!show_solde && !show_mode_transfert">
                            <div v-if="show_btn_confirm">
                                <button type="button" @click="cardInfos" :class="!infos_card_ecobank.card_id || !infos_card_ecobank.last_4_digits ||
                                    infos_card_ecobank.card_id.length < 8 || infos_card_ecobank.last_4_digits.length < 4 ?
                                    'btn btn-dark btn-disabled-custom btn-block' : 'btn btn-dark btn-block'
                                    " :disabled="!infos_card_ecobank.card_id || !infos_card_ecobank.last_4_digits ||infos_card_ecobank.card_id.length < 8 || infos_card_ecobank.last_4_digits.length < 4"
                                >
                                    Vérifier les infos de la carte
                                </button>
                            </div>

                            <div class="text-center flex flex-column-reverse align-items-center justify-content-center mt-5"
                                v-else>
                                <span class="my-3 font-15 text-dark">Veuillez patienter</span>
                                <i class="fa fa-spinner fa-spin font-20"></i>
                            </div>
                        </div>
                        <!-- ****************** -->
                        <!-- ****************** -->
                        <!-- Button submit form -->
                        <!-- ****************** -->
                        <!-- ****************** -->

                        <!-- ************************ -->
                        <!-- ************************* -->
                        <!-- Solde de la carte Ecobank -->
                        <!-- ************************* -->
                        <!-- ************************* -->
                        <div class="element-pair my-3" v-if="show_solde">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>Solde de la carte</span>
                                    </div>
                                    <div class="font-weight-bold" style="font-size: 2rem;">
                                        {{
                                            new Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "XOF",
                                            }).format(card.Balance)
                                        }}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- ************************* -->
                        <!-- ************************* -->
                        <!-- Solde de la carte Ecobank -->
                        <!-- ************************* -->
                        <!-- ************************* -->

                        <!-- ********************************** -->
                        <!-- ********************************** -->
                        <!-- Afficher les méthodes de transfert -->
                        <!-- ********************************** -->
                        <!-- ********************************** -->
                        <div class="element-pair my-3" v-if="show_mode_transfert">
                            <div class="element-label">
                                <span>
                                    Méthode de transfert
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <!-- ********* -->
                                        <!-- ********* -->
                                        <!-- Carte UBA -->
                                        <!-- ********* -->
                                        <!-- ********* -->
                                        <div v-if="uba_card.length > 0">
                                            <div class="formule-bloc" :style="mode_transfert == 'uba'
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; border: 1px solid #b2bec3'" id="uba"
                                                key="uba" @click="setMethode('uba')">
                                                <div style="display: flex; justify-content: flex-end; align-items: center;">
                                                    <input type="radio" name="methode" value="uba" ref="ubaRadio"
                                                        style="cursor: pointer; width: 18px; height: 18px"
                                                        @change="setMethode('uba')" v-model="mode_transfert" />
                                                </div>
                                                <div
                                                    style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                                    <i class="fa fa-credit-card font-18 mb-2"></i>
                                                    <span class="font-16">
                                                        Carte UBA
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ********* -->
                                        <!-- ********* -->
                                        <!-- Carte UBA -->
                                        <!-- ********* -->
                                        <!-- ********* -->

                                        <!-- ************ -->
                                        <!-- ************ -->
                                        <!-- Mobile Money -->
                                        <!-- ************ -->
                                        <!-- ************ -->
                                        <div>
                                            <div class="formule-bloc" :style="mode_transfert == 'mobile_money'
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; border: 1px solid #b2bec3'"
                                                id="mobile_money" key="mobile_money" @click="setMethode('mobile_money')">
                                                <div style="display: flex; justify-content: flex-end; align-items: center;">
                                                    <input type="radio" name="methode" value="mobile_money"
                                                        ref="mobileMoneyRadio"
                                                        style="cursor: pointer; width: 18px; height: 18px"
                                                        @change="setMethode('mobile_money')" v-model="mode_transfert" />
                                                </div>
                                                <div
                                                    style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                                    <i class="fa fa-phone font-18 mb-2"></i>
                                                    <span class="font-16">
                                                        Mobile Money
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ************ -->
                                        <!-- ************ -->
                                        <!-- Mobile Money -->
                                        <!-- ************ -->
                                        <!-- ************ -->
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- ********************************** -->
                        <!-- ********************************** -->
                        <!-- Afficher les méthodes de transfert -->
                        <!-- ********************************** -->
                        <!-- ********************************** -->

                        <!-- ******************** -->
                        <!-- ******************** -->
                        <!-- Mode transfert = uba -->
                        <!-- ******************** -->
                        <!-- ******************** -->
                        <div class="element-pair my-3" v-if="mode_transfert === 'uba'">
                            <!-- **************** -->
                            <!-- **************** -->
                            <!-- Liste des cartes -->
                            <!-- **************** -->
                            <!-- **************** -->
                            <div>
                                <ul class="list-unstyled media-list-divider text-left" :style="uba_card.length > 5
                                    ? 'height: 392px; overflow-y: scroll;'
                                    : 'height: auto;'
                                    ">
                                    <li class="media card-uba-bloc py-3" v-for="(carte, i) in uba_card" :key="i" style="cursor: pointer; display: flex; padding: 10px; align-items: center; 
                                            border: 1px solid rgb(178, 190, 195); border-radius: 10px;
                                        " :style="index_carte_uba === i
                                            ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                            : 'background-color: #fdfdfd; border: 1px solid #b2bec3'"
                                        @click="selectCardUba(carte, i)">
                                        <i class="fa fa-credit-card font-30 mr-3"></i>
                                        <div class="media-body flexbox" style="flex-direction: row; align-items: center;">
                                            <div>
                                                <h6 class="mt-0 mb-1">
                                                    Carte UBA
                                                    <span class="font-weight-bold">
                                                        {{
                                                            carte.Idclient
                                                        }}
                                                    </span>
                                                </h6>
                                                <span class="font-12">
                                                    Visa **** {{ carte.last_digit }}
                                                </span>
                                            </div>
                                            <div v-if="index_carte_uba === i">
                                                <i class="fa fa-check-circle font-20" style="color: rgb(0, 92, 200);"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- **************** -->
                            <!-- **************** -->
                            <!-- Liste des cartes -->
                            <!-- **************** -->
                            <!-- **************** -->
                        </div>
                        <!-- ******************** -->
                        <!-- ******************** -->
                        <!-- Mode transfert = uba -->
                        <!-- ******************** -->
                        <!-- ******************** -->

                        <!-- ***************************** -->
                        <!-- ***************************** -->
                        <!-- Mode transfert = mobile_money -->
                        <!-- ***************************** -->
                        <!-- ***************************** -->
                        <div class="element-pair my-3" v-if="mode_transfert === 'mobile_money'">
                            <div class="element-label">
                                <span>
                                    Votre numéro mobile money
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                        placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code" label="country"
                                        :searchable="true" :select-label="''" :deselect-label="''" :selected-label="'✓'"
                                        :allow-empty="false" disabled>
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            {{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                        v-model="mobile_money_number" name="telephone" @input="checkNumber"
                                        style="font-weight: 900; font-size: 22px" />
                                </div>
                            </div>
                        </div>

                        <div class="element-pair my-5" v-if="mode_transfert === 'mobile_money'">
                            <div class="modalBody-operators" v-if="isNumberSetted" ref="operatorsContainer">
                                <div v-for="operator in operators[keyOperator]" :key="operator.id"
                                    :class="operator_selected === operator.name ? 'operator-block selected' : 'operator-block'"
                                    :id="operator.id" @click="() => {
                                        chooseOperator(operator.name)
                                        name_operator = operator.title
                                    }" :ref="operator.name">
                                    <div class="img-container">
                                        <img :src="require(`../../assets/img/operator/${operator.logo}`)"
                                            alt="Operator logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ***************************** -->
                        <!-- ***************************** -->
                        <!-- Mode transfert = mobile_money -->
                        <!-- ***************************** -->
                        <!-- ***************************** -->


                        <!-- ****************** -->
                        <!-- ****************** -->
                        <!-- Button submit form -->
                        <!-- ****************** -->
                        <!-- ****************** -->
                        <div class="element-wrap element-wrap-for-submit" v-if="show_mode_transfert">
                            <div v-if="show_btn_confirm">
                                <button type="button" @click="setDataDebit"
                                    :class="!mode_transfert ? 'btn btn-dark btn-disabled-custom btn-block' : 'btn btn-dark btn-block'"
                                    :disabled="!mode_transfert"
                                >
                                    Transférer
                                    {{
                                        new Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(card.Balance)
                                    }} 
                                    {{ 
                                        mode_transfert === 'mobile_money' ? 'vers Mobile money' : mode_transfert === 'uba' ? 'vers votre carte UBA' : '' 
                                    }}
                                </button>
                            </div>

                            <div class="text-center flex flex-column-reverse align-items-center justify-content-center mt-5"
                                v-else>
                                <span class="my-3 font-15 text-dark">Veuillez patienter</span>
                                <i class="fa fa-spinner fa-spin font-20"></i>
                            </div>
                        </div>
                        <!-- ****************** -->
                        <!-- ****************** -->
                        <!-- Button submit form -->
                        <!-- ****************** -->
                        <!-- ****************** -->

                    </form>
                </div>
                <!-- **** -->
                <!-- **** -->
                <!-- Body -->
                <!-- **** -->
                <!-- **** -->

            </div>
        </div>

        <!-- ************************* -->
        <!-- ************************* -->
        <!-- Modal de paiement Orabank -->
        <!-- ************************* -->
        <!-- ************************* -->
        <b-modal class="modal-content" id="openModal" ref="openModal" no-close-on-backdrop hide-footer
            hide-header scrollable style="padding: 0" size="lg">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                @click="closeModal('openModal')">
                <i class="fas fa-times"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                @click="closeModal('openModal')">
                <i class="fas fa-times" style="font-size: 23px; color: #ffffff"></i>
            </div>
            <div class="modalOrangeMoney">
                <iframe :src="link" frameborder="0" scrolling="yes" style="height: inherit; width: 100%"></iframe>
            </div>
        </b-modal>
        <!-- ************************* -->
        <!-- ************************* -->
        <!-- Modal de paiement Orabank -->
        <!-- ************************* -->
        <!-- ************************* -->

    </div>
</template>

<script>

    import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
    import { sweetAlert } from "@/constants/functions";
    import axios from "axios";
    import Multiselect from "vue-multiselect";
    import json from "@/constants/flags.json";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";

    const MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
    ];

    export default {
        created() {
        },
        props: {
            reCaptchaCompat: {
                type: Boolean,
                default: true,
            },
            sentry: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            Loading,
            VueHcaptcha,
            multiselect: Multiselect,
        },
        data() {
            return {
                infos_card_ecobank: { card_id: "", last_4_digits: "" }, token: null, eKey: null, verified: false, show_btn_confirm: true,
                base_url: "https://carte.abidjan.net/", card: {}, mode_transfert: "", uba_card: [], show_mode_transfert: false, show_solde: false,
                reference_carte_uba: "", index_carte_uba: null, mobile_money_number: "", indice: { i: "225" }, options: [], json: [], isNumberSetted: false,
                operators: [
                    [
                        {
                            id: 1,
                            name: "orange",
                            logo: "orange.png",
                            title: 'Orange Money'
                        },
                        {
                            id: 2,
                            name: "wave",
                            logo: "wave.png",
                            title: 'Wave'
                        }
                    ],
                    [
                        {
                            id: 1,
                            name: "moov",
                            logo: "moov.png",
                            title: 'Flooz'
                        }, {
                            id: 2,
                            name: "wave",
                            logo: "wave.png",
                            title: 'Wave'
                        }
                    ],
                    [
                        {
                            id: 1,
                            name: "mtn",
                            logo: "mtn.png",
                            title: 'MTN'
                        }, {
                            id: 2,
                            name: "wave",
                            logo: "wave.png",
                            title: 'Wave'
                        }
                    ],
                ], keyOperator: 0, operator_selected: "", name_operator: "", isLoading: false, fullPage: true, link: "",
                data_debit: {
                    customer_id: "",
                    amount: "",
                    phone_number: "",
                    methode_retrait: "", // 1 pour le mobile money, 2 pour la carte uba
                    destination_mobile_money_number: "",
                    destination_mobile_money_network: "",
                    customer_bank: "ecobank",
                    destination_carte_reference: "",
                    pan: "",
                    expiry: "",
                    cvv: "",
                    cardholderName: ""
                }, 
            };
        },
        methods: {

            /**
             * Fonction permettant de fixer l'indicatif de
             */
            indicatifsTel: function () {
                this.json = [...json];
                this.options = [];

                for (let j of this.json) {
                    this.options.push({
                        country_code: j.flag + " +" + j.dial_code,
                        country: j.flag + " " + j.name + " +" + j.dial_code,
                        code: j.dial_code,
                    });
                }

                // Indicatifs
                this.indice.i = this.options.find(
                    (option) => option.code === this.indice.i
                );
            },

            /**
             * Fonction usuelle pour le Hcaptcha
             */
            onVerify(token, ekey) {
                this.verified = true;
                this.token = token;
                this.eKey = ekey;
                console.log(`Callback token: ${token}, ekey: ${ekey}`);
            },

            /**
             * Fonction usuelle pour le Hcaptcha
             */
            onExpired() {
                this.verified = false;
                this.token = null;
                this.eKey = null;
                this.expired = true;
                console.log("Expired");
            },

            /**
             * Fonction usuelle pour le Hcaptcha
             */
            onError(err) {
                this.token = null;
                this.eKey = null;
                this.error = err;
                this.verified = false;
                console.log(`Error: ${err}`);
            },

            onCancel() {
                console.log("Loader dismiss");
            },

            scrollToTop() {
                window.scrollTo(0, 0);
            },

            closeModal(refModal) {
                this.scrollToTop();
                this.$refs[refModal].hide();
            },

            /**
             * Fonction pour vérifier qu'une carte est Ecobank
             */
            cardInfos: async function () {

                // On s'assure d'abord que toutes les données requises sont correctement renseignées
                if (!this.infos_card_ecobank.card_id || !this.infos_card_ecobank.last_4_digits) {
                    sweetAlert(
                        this.$swal, true, "top-end", "info", "Info",
                        "Veuillez renseigner tous les champs !"
                    );
                    return false;
                }

                // On vérifie la taille des valeurs des champs maintenant
                if (this.infos_card_ecobank.card_id.length < 8) {
                    sweetAlert(
                        this.$swal, true, "top-end", "info", "Info",
                        "Veuillez renseigner l'ID de la carte sur 8 positions !"
                    );
                    return false;
                }
                if (this.infos_card_ecobank.last_4_digits.length < 4) {
                    sweetAlert(
                        this.$swal, true, "top-end", "info", "Info",
                        "Veuillez renseigner les 4 derniers chiffres de votre carte !"
                    );
                    return false;
                }

                // On vérifie que le captcha est validé maintenant
                if (this.token == null) {

                    this.$swal(
                        'Info !',
                        'Veuillez valider le captcha !',
                        'info'
                    );

                    return false;
                }

                // On met la valeur de show_btn_confirm à false et on affiche la section qui demande de patienter
                this.show_btn_confirm = false;

                // Toutes les données sont renseignées correctement, 
                // on exécute notre api pour checker les infos de la carte
                let dataToSend = new FormData();
                dataToSend.append("customerID", parseInt(this.infos_card_ecobank.card_id));
                dataToSend.append("last4Digit", this.infos_card_ecobank.last_4_digits);

                axios.post(this.base_url + 'demandeRetrait/DemandeRetraitController.php?link=getuserandcarteinfos',
                    dataToSend
                ).then((response) => {

                    const { status_code } = response.data;
                    const { data: { data } } = response;

                    if (status_code === 200) {

                        this.card = { ...data };

                        // Les données de la carte à débiter
                        this.data_debit.customer_id = this.infos_card_ecobank.card_id;
                        this.data_debit.expiry = this.resolveMonth(data.ExpireDate);
                        this.data_debit.pan = data.Pan;
                        this.data_debit.cvv = data.Cvv;
                        this.data_debit.phone_number = data.PhoneNumber;
                        this.data_debit.cardholderName = data.Nom + " " + data.Prenom;
                        this.data_debit.amount = parseInt(data.Balance);

                        if ( data.Balance <= 0 )
                        {
                            this.$swal('Attention!', 'Votre solde est insuffisant pour effectuer cette action!', 'info');
                            this.show_btn_confirm = true ;
                            return false ;
                        }

                        this.cardUBAInfos();
                        return false;
                    }

                    this.$swal('Attention !', data?.ErrorMessage, 'info');
                    console.log("<=== Response ===>");
                    console.log(response)
                    console.log("<=== Response ===>");
                    this.show_btn_confirm = true ;

                }).catch((error) => {
                    this.show_btn_confirm = true ;
                    console.log("<=== Error ===>");
                    console.error("API Error:", error);
                    console.log("<=== Error ===>");
                    this.$swal('Erreur !', error.response?.data?.message || 'Une erreur est survenue lors de la communication avec le serveur.', 'error');
                }).finally(() => {
                    // this.show_btn_confirm = true;
                });

            },

            cardUBAInfos: async function () {

                let dataToSend = new FormData();
                dataToSend.append("phoneNumber", this.card.PhoneNumber);

                await axios.post(this.base_url + 'demandeRetrait/DemandeRetraitController.php?link=checkcarteexist',
                    dataToSend
                ).then((response) => {

                    const { status_code } = response.data;
                    const { data: { data } } = response;
                    this.show_btn_confirm = true;
                    this.show_solde = true;
                    this.show_mode_transfert = true;

                    if (status_code === 200) {
                        this.uba_card = [...data.cartes];
                        return false;
                    }

                }).catch((error) => {
                    console.error("API Error:", error);
                    this.show_btn_confirm = true;
                    // this.$swal('Erreur !', error.response?.data?.message || 'Une erreur est survenue lors de la communication avec le serveur.', 'error');
                }).finally(() => {
                });

            },

            setMethode: function (method) {
                this.mode_transfert = method;

                // Simulate click on the corresponding radio input
                if (method === 'uba') {
                    this.$refs.ubaRadio.click();
                } else if (method === 'mobile_money') {
                    this.$refs.mobileMoneyRadio.click();
                }
            },

            selectCardUba: function (carte, index) {
                this.index_carte_uba = index;
                this.reference_carte_uba = carte.reference;
            },

            checkNumber: function () {

                if (this.mobile_money_number.replace(/\s/g, '').length === 10) {

                    const digitOperator = this.mobile_money_number.slice(0, 2);

                    if (digitOperator === '07') {
                        this.keyOperator = 0;
                        this.isNumberSetted = true;
                    }

                    if (digitOperator === '01') {
                        this.keyOperator = 1;
                        this.isNumberSetted = true;
                    }

                    if (digitOperator === '05') {
                        this.keyOperator = 2;
                        this.isNumberSetted = true;
                    }

                    if (digitOperator !== '07' && digitOperator !== '01' && digitOperator !== '05') {
                        // this.isNumberIncorrect = true;
                        this.isNumberSetted = false;
                    }

                } else {

                    const el = this.$refs['operatorsContainer'];
                    if (el) {
                        el.classList.add('operatorsOut');
                    }

                    this.isNumberSetted = false;
                }
            },

            chooseOperator(operator) {

                const element = this.$refs[operator];

                if (this.operator_selected === operator) {
                    this.operator_selected = '';
                    element[0].classList.toggle('selected');
                } else {
                    this.operator_selected = operator;
                }
            },

            resolveMonth: function (_date) {
                let date_splitted = _date.split("-")[1];
                let date_formated = _date.split("-")[2];

                return `${date_formated}-${String(MONTHS.indexOf(date_splitted) + 1).padStart(2, '0')}`;
            },

            setDataDebit: function () {

                if (!this.mode_transfert) {
                    sweetAlert(
                        this.$swal, true, "top-end", "info", "Info",
                        "Veuillez sélectionner le mode de transfert de votre argent !"
                    );
                    return false
                }

                if (this.mode_transfert === 'uba') {
                    if (!this.reference_carte_uba) {

                        sweetAlert(
                            this.$swal, true, "top-end", "info", "Info",
                            "Veuillez sélectionner la carte UBA sur laquelle effectuer le transfert !"
                        );
                        return false;
                    }

                    this.data_debit.destination_carte_reference = this.reference_carte_uba;
                    this.data_debit.destination_mobile_money_number = "";
                    this.data_debit.destination_mobile_money_network = "";
                    this.data_debit.methode_retrait = "2";  // carte uba
                }

                if (this.mode_transfert === 'mobile_money') {

                    if (this.mobile_money_number.replace(/\s/g, '').length !== 10) {

                        sweetAlert(
                            this.$swal, true, "top-end", "info", "Info",
                            "Veuillez renseigner votre numéro mobile money sur 10 positions !"
                        );
                        return false;
                    }

                    if (!this.operator_selected) {

                        sweetAlert(
                            this.$swal, true, "top-end", "info", "Info",
                            "Veuillez sélectionner votre opérateur mobile money !"
                        );
                        return false;
                    }

                    this.data_debit.destination_carte_reference = "";
                    this.data_debit.destination_mobile_money_number = this.mobile_money_number.replace(/\s/g, '');
                    this.data_debit.destination_mobile_money_network = this.operator_selected;
                    this.data_debit.methode_retrait = "1";  // mobile money
                }

                // On soumet les data après toutes les vérifications effectuées
                this.sendDataDebit();
            },

            sendDataDebit: function () {

                this.isLoading = true ;

                let dataToSend = new FormData();
                dataToSend.append("customer_id", this.data_debit.customer_id)
                dataToSend.append("amount", this.data_debit.amount)
                dataToSend.append("phone_number", this.data_debit.phone_number)
                dataToSend.append("methode_retrait", this.data_debit.methode_retrait)
                dataToSend.append("destination_mobile_money_number", this.data_debit.destination_mobile_money_number)
                dataToSend.append("destination_mobile_money_network", this.data_debit.destination_mobile_money_network)
                dataToSend.append("customer_bank", this.data_debit.customer_bank)
                dataToSend.append("destination_carte_reference", this.data_debit.destination_carte_reference)
                dataToSend.append("pan", this.data_debit.pan)
                dataToSend.append("expiry", this.data_debit.expiry)
                dataToSend.append("cvv", this.data_debit.cvv)
                dataToSend.append("cardholderName", this.data_debit.cardholderName)

                axios.post(this.base_url + "demandeRetrait/DemandeRetraitController.php?link=initOrabank",
                    dataToSend
                ).then((response) => {
                    
                    const {data} = response;
                    console.log(data)

                    if (data?.code === 200){

                        this.link = data.link ;
                        // this.$refs['openModal'].show();
                        // On redirige l'utilisateur sur le lien de paiement Orabank
                        window.location.href = data.link;
                        return false;
                    }

                    sweetAlert(
                        this.$swal, true, "top-end", "info", "Info",
                        "Une erreur s'est produite durant l'exécution de votre requête ! Veuillez réessayer."
                    );
                    this.isLoading = false ;

                }).catch((error) => {

                    this.isLoading = false ;
                    console.log(error)
                    this.$swal('Erreur !', error.response?.data?.message || 'Une erreur est survenue lors de la communication avec le serveur.', 'error');
                })

            }
        },
        mounted() {
            this.indicatifsTel();
        }
    }

</script>

<style scoped>
.btn-disabled-custom {
    background: rgb(0 0 0 / 8%);
    border: rgb(0 0 0 / 8%);
    color: #1b1e21;
    cursor: not-allowed;
}

.formules-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .formules-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 96px;
        grid-gap: 20px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .tooltip .tooltip-inner {
        display: none !important;
    }

    /* .formules-container::-webkit-scrollbar {
                        display: none;
                    } */
}

.formule-bloc {
    position: relative;
    background-color: #f6f6f6;
    /* border: 1px solid #b2bec3; */
    border-radius: 10px;
    width: 135px;
    height: 96px;
    max-height: 100px;
    padding: 5px;
    cursor: pointer;

    font-size: 12px;
    transition: all 0.3s ease-in-out;
}

.formule-bloc-disabled {
    position: relative;
    background-color: #eeeeeeb5 !important;
    color: #6a6c6e !important;
    border: 1px solid #b2bec3;

    border-radius: 5px;
    width: 135px;
    height: 96px;
    max-height: 100px;
    padding: 5px;
    cursor: pointer;

    font-size: 12px;
    transition: all 0.3s ease-in-out;
}

.formule-bloc:hover {
    transform: scale(1.05);
    background-color: #fdfdfd;
}

.card-uba-bloc {
    transition: all 0.3s ease-in-out;
}

.card-uba-bloc:hover {
    transform: scale(1.02);
    background-color: #fdfdfd;
}

@media (max-width: 600px) {
    .formule-bloc {
        height: 95px;
        width: 119px;
    }

    .formule-bloc:hover {
        transform: scale(1);
    }
}

@media (max-width: 480px) {
    .formule-bloc {
        height: 7rem;
        width: 10rem;
    }

    .formule-bloc:hover {
        transform: scale(1);
    }

    .card-uba-bloc:hover {
        transform: scale(1);
    }
}

.modalBody-operators {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out;
    animation: fadeInOperators .3s linear;
}

.operator-block {
    height: 10rem;
    width: 10rem;
    margin: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    transition: all .3s ease-in-out;
    background-color: #F9F9F9;
    box-shadow: 0px 13px 18px rgba(0, 0, 0, .1);
}

.operator-block .img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    overflow: hidden;
}

.operator-block:hover {
    transform: scale(1.05);
}

.operator-block.selected {
    border: 3px solid #176BEF;
    transform: scale(1.05);
}
</style>